* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.swm-system {
    width: 100%;
    height: 100vh;
    display: flex;
}

.swms-sub {
    height: 100%;
    width: 100%;
    display: block;
}

.swms-first-img {
    width: 100%;
    height: 100%;
}

.swms-new-img {
    width: 100%;
    height: 100%;
}

.swms-first-phone-view {
    display: none;
}

.swms-page-contact {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
}

.swms-text-contact {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    padding: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 32px;
    color: #FFFFFF;
}

.swms-para-contact {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 70%;
    text-align: justify;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.hand-img {
    display: flex;
    align-items: center;
    width: 100%;
}

.swms-right-hand-img {
    display: flex;
    align-items: flex-end;
    height: 70%;
    width: 60%;
}

.swms-second-img {
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.second-img {
    width: 50%;
}

.swms-second-contact {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.swms-second-para {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    font-family: 'Montserrat';
    text-align: justify;
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 25px;
    color: #010628;
}

.swms-second-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.swms-third-img {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.swms-third-contact {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 80%;
}

.swms-third-para {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 42%;
    height: 35%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #010628;
}

.third-img {
    width: 90%;
}

.swms-fourth-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.swms-fourth-contact {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    height: 95%;
    width: 80%;
}

.fourth-img {
    width: 100%;
    height: 100%;
}

.swms-apple-page {
    width: 55%;
    height: 100%;
}

.swms-content-contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #010628;
}

.swms-whole-text {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.swms-title-download {
    height: 10%;
}

.available-img {
    width: 40%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.download-para {
    width: 30%;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
}

.system-Architecture {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swms-Architecture-page {
    width: 60%;
}

.user-interface {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.swms-user-page {
    width: 100%;
}

.user-img {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swms-content-liner-color-img {
    width: 100%;
    height: 30%;
    background-image: url("/src/assets/images/vital-page-liner-color-img.png");
    display: flex;
    flex-direction: row;
    background-size: cover;
}

.swms-consult {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    text-align: justify;
    width: 50%;
}

.swms-content-liner-color {
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.swms-page-btn-liner {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swms-btn-lets {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 50%;
    background: #E8FA7E;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.35);
    border-radius: 18px;
}

.swms-lets-talk {
    background-color: #e8fa7e;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    padding: 10px 50px;
    cursor: pointer;
}

.architech-img {
    width: 80%;
}

.swms-para-contact-phone-view {
    display: none;
}

@media only screen and (max-width: 320px) {
    .swms-consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;

    }

    .swms-sub {
        height: 100%;
        width: 100%;
        display: block;
    }

    .swms-first-phone-view {
        width: 100%;
        height: 42%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-new-img {
        width: 100%;
        height: 100%;
    }

    .swms-first-img {
        display: none;
    }

    .swms-page-contact {
        justify-content: flex-end;
        align-items: flex-start;
        height: 95%;
        width: 45%;
        flex-direction: column;
    }

    .swms-text-contact {
        width: 50%;
        height: 27%;
        font-size: 6px;
        line-height: 11px;
        font-weight: 600;
    }

    .swms-para-contact {
        justify-content: center;
        text-align: justify;
        width: 179px;
        font-size: 6px;
        height: -12%;
        font-weight: 550;
        line-height: 9px;
    }

    .swms-right-hand-img {
        width: 159px;
    }

    .hand-img {
        width: 156px;
        height: 199px;
        position: relative;
        left: -7px;
    }

    .swms-text-contact-phone-view {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        padding-bottom: 12px;
        text-align: center;
        color: white;
    }

    .swms-para-contact-phone-view {
        display: flex;
        padding: 10px;
        text-align: justify;
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 550;
    }

    .swms-phone-view-first-text {
        padding: 20px;
        margin-top: -76px;
    }

    .swms-second-img {
        width: 100%;
        height: 90%;
        justify-content: space-around;
    }

    .second-img {
        width: 100%;
        height: 85%;
    }

    .swms-second-contact {
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: 100%;
        flex-direction: column-reverse;
        padding: 20px;

    }

    .swms-second-para {
        line-height: 20px;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 550;
        text-align: justify;
    }

    .swms-second-page {
        width: 82%;
        height: 100%;
    }

    .swms-third-img {
        width: 100%;
        height: 30%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-third-contact {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: 90%;
    }

    .swms-third-para {
        height: 15%;
        width: 50%;
    }

    .third-img {
        width: 115px;
        height: 140px;
    }

    .swms-fourth-img {
        width: 100%;
        height: 35%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-fourth-contact {
        justify-content: center;
        flex-direction: row;
        align-items: center;
        height: 95%;
        width: 80%;
    }

    .fourth-img {
        width: 100%;
        height: 100%;
    }

    .swms-apple-page {
        width: 70%;
        height: 100%;
    }

    .swms-content-contact {
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 5px;
    }

    .swms-whole-text {
        height: 70%;
        width: 100%;
    }

    .swms-title-download {
        height: 15%;
    }

    .available-img {
        width: 42%;
        height: 39%;
        align-items: center;
        justify-content: center;
    }

    .download-para {
        width: 50%;
        text-align: center;
        line-height: 6px;
        font-size: 4px;
    }

    .system-Architecture {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-Architecture-page {
        width: 100%;
        height: 40%;
    }

    .architech-img {
        width: 100%;
    }

    .user-interface {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-user-page {
        width: 100%;
    }

    .user-img {
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
    }

    .swms-content-liner-color-img {
        height: 20%;
    }


    .swms-btn-lets {
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 90%;
        border-radius: 18px;
    }

    .swms-lets-talk {
        font-size: 12px;
        padding: 10px 10px;
    }
}

@media only screen and (min-width:320px) and (max-width: 375px) {
    .swms-sub {
        height: 100%;
        width: 100%;
        display: block;
    }

    .swms-consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;

    }

    .swms-first-phone-view {
        width: 100%;
        height: 42%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-new-img {
        width: 100%;
        height: 100%;
    }

    .swms-first-img {
        display: none;
    }

    .swms-page-contact {
        justify-content: flex-end;
        align-items: flex-start;
        height: 95%;
        width: 45%;
        flex-direction: column;
    }

    .swms-text-contact {
        width: 50%;
        height: 27%;
        font-size: 6px;
        line-height: 11px;
        font-weight: 600;
    }

    .swms-para-contact {
        justify-content: center;
        text-align: justify;
        width: 179px;
        font-size: 6px;
        height: -12%;
        font-weight: 550;
        line-height: 9px;
    }

    .swms-right-hand-img {
        display: none;
    }

    .hand-img {
        display: none;
    }

    .swms-text-contact-phone-view {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        padding-bottom: 12px;
        text-align: center;
        color: white;
    }

    .swms-para-contact-phone-view {
        display: flex;
        padding: 10px;
        text-align: justify;
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 550;
    }

    .swms-phone-view-first-text {
        padding: 20px;
        margin-top: -77px;
    }

    .swms-second-img {
        width: 100%;
        height: 75%;
        justify-content: space-around;
    }

    .second-img {
        width: 100%;
        height: 90%;
    }

    .swms-second-contact {
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        height: 90%;
        width: 100%;
        flex-direction: column-reverse;
        padding: 20px;
    }

    .swms-second-para {
        line-height: 20px;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 550;
        text-align: justify;
    }

    .swms-second-page {
        width: 65%;
        height: 100%;
    }

    .swms-third-img {
        width: 100%;
        height: 30%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-third-contact {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: 90%;
    }

    .swms-third-para {
        height: 15%;
        width: 50%;
    }

    .third-img {
        width: 115px;
        height: 140px;
    }

    .swms-fourth-img {
        width: 100%;
        height: 35%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-fourth-contact {
        justify-content: center;
        flex-direction: row;
        align-items: center;
        height: 95%;
        width: 80%;
    }

    .fourth-img {
        width: 100%;
        height: 100%;
    }

    .swms-apple-page {
        width: 70%;
        height: 100%;
    }

    .swms-content-contact {
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 5px;
    }

    .swms-whole-text {
        height: 70%;
        width: 100%;
    }

    .swms-title-download {
        height: 15%;
    }

    .available-img {
        width: 42%;
        height: 39%;
        align-items: center;
        justify-content: center;
    }

    .download-para {
        width: 50%;
        text-align: center;
        line-height: 6px;
        font-size: 4px;
    }

    .system-Architecture {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-Architecture-page {
        width: 100%;
        height: 40%;
    }

    .architech-img {
        width: 100%;
    }

    .user-interface {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-user-page {
        width: 100%;
    }

    .user-img {
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
    }

    .swms-content-liner-color-img {
        height: 20%;
    }


    .swms-btn-lets {
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 90%;
        border-radius: 18px;
    }

    .swms-lets-talk {
        font-size: 12px;
        padding: 10px 10px;
    }
}

@media only screen and (min-width:375px) and (max-width: 425px) {
    .swms-sub {
        height: 100%;
        width: 100%;
        display: block;
    }

    .swms-consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;

    }

    .swms-first-phone-view {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-new-img {
        width: 100%;
        height: 100%;
    }

    .swms-first-img {
        display: none;
    }

    .swms-page-contact {
        justify-content: flex-end;
        align-items: flex-start;
        height: 95%;
        width: 45%;
        flex-direction: column;
    }

    .swms-text-contact {
        width: 50%;
        height: 27%;
        font-size: 6px;
        line-height: 11px;
        font-weight: 600;
    }

    .swms-para-contact {
        justify-content: center;
        text-align: justify;
        width: 179px;
        font-size: 6px;
        height: -12%;
        font-weight: 550;
        line-height: 9px;
    }

    .swms-right-hand-img {
        display: none;
    }

    .hand-img {
        display: none;
    }

    .swms-text-contact-phone-view {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        padding-bottom: 12px;
        color: white;

    }

    .swms-para-contact-phone-view {
        display: flex;
        text-align: justify;
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 550;
    }

    .swms-phone-view-first-text {
        padding: 20px;
        margin-top: -78px;
    }


    .swms-second-img {
        width: 100%;
        height: 75%;
        justify-content: space-around;
    }

    .second-img {
        width: 100%;
        height: 90%;
    }

    .swms-second-contact {
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        height: 90%;
        width: 100%;
        flex-direction: column-reverse;
        padding: 20px;

    }

    .swms-second-para {
        line-height: 20px;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 550;
        text-align: justify;
    }

    .swms-second-page {
        width: 65%;
        height: 100%;
    }

    .swms-third-img {
        width: 100%;
        height: 30%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-third-contact {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: 90%;
    }

    .swms-third-para {
        width: 50%;
    }

    .third-img {
        width: 160px;
    }

    .swms-fourth-img {
        width: 100%;
        height: 35%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-fourth-contact {
        justify-content: center;
        flex-direction: row;
        align-items: center;
        height: 95%;
        width: 80%;
    }

    .fourth-img {
        width: 100%;
        height: 100%;
    }

    .swms-apple-page {
        width: 70%;
        height: 100%;
    }

    .swms-content-contact {
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 5px;
    }

    .swms-whole-text {
        height: 70%;
        width: 100%;
    }

    .swms-title-download {
        height: 15%;
    }

    .available-img {
        width: 42%;
        height: 39%;
        align-items: center;
        justify-content: center;
    }

    .download-para {
        width: 50%;
        text-align: center;
        line-height: 6px;
        font-size: 4px;
    }

    .system-Architecture {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-Architecture-page {
        width: 100%;
        height: 40%;
    }

    .architech-img {
        width: 365px;
    }

    .user-interface {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-user-page {
        width: 100%;
    }

    .user-img {
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
    }

    .swms-content-liner-color-img {
        height: 20%;
    }

    .swms-btn-lets {
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 90%;
        border-radius: 18px;
    }

    .swms-lets-talk {
        font-size: 12px;
        padding: 10px 10px;
    }
}

@media only screen and (min-width:425px) and (max-width: 767px) {
    .swms-sub {
        height: 100%;
        width: 100%;
        display: block;
    }

    .swms-consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;

    }

    .swms-first-phone-view {
        width: 100%;
        height: 65%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-first-img {
        display: none;
    }

    .swms-new-img {
        width: 100%;
        height: 100%;
    }

    .swms-page-contact {
        justify-content: flex-end;
        align-items: flex-start;
        height: 95%;
        width: 45%;
        flex-direction: column;
    }

    .swms-text-contact {
        width: 50%;
        height: 27%;
        font-size: 6px;
        line-height: 11px;
        font-weight: 600;
    }

    .swms-para-contact {
        justify-content: center;
        text-align: justify;
        width: 179px;
        font-size: 6px;
        height: -12%;
        font-weight: 550;
        line-height: 9px;
    }



    .swms-text-contact-phone-view {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        padding-bottom: 12px;
        text-align: center;
        color: white;
    }

    .swms-para-contact-phone-view {
        display: flex;
        padding: 10px;
        text-align: justify;
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 550;
    }

    .swms-phone-view-first-text {
        padding: 20px;
        margin-top: -65px;
    }

    .swms-second-img {
        width: 100%;
        height: 75%;
        justify-content: space-around;
    }

    .second-img {
        width: 100%;
        height: 90%;
    }

    .swms-second-contact {
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        height: 90%;
        width: 100%;
        flex-direction: column-reverse;
        padding: 20px;

    }

    .swms-second-para {
        line-height: 20px;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 550;
        text-align: justify;
    }

    .swms-second-page {
        width: 65%;
        height: 100%;
    }

    .swms-third-img {
        width: 100%;
        height: 45%;
        align-items: flex-start;
        justify-content: space-around;
    }

    .swms-third-contact {
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        width: 95%;

    }

    .swms-third-para {
        justify-content: center;
        flex-direction: column;
        width: 140%;
        height: 15%;
        font-size: 11px;
        line-height: 25px;
        padding-left: 31px;
    }

    .third-img {
        width: 100%;
        height: 100%;
    }

    .swms-fourth-img {
        width: 100%;
        height: 76%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-fourth-contact {
        justify-content: center;
        flex-direction: row;
        align-items: center;
        height: 95%;
        width: 80%;
    }

    .fourth-img {
        width: 100%;
    }

    .swms-apple-page {
        width: 100%;
        height: 100%;
    }

    .swms-content-contact {
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        font-size: 15px;
        line-height: 25px;
    }

    .swms-whole-text {
        height: 56%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .swms-title-download {
        height: 10%;
        font-size: 12px;
    }

    .available-img {
        width: 60%;
        height: 30%;
        align-items: center;
        justify-content: center;
    }

    .download-para {
        line-height: 20px;
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: 550;
    }

    .system-Architecture {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-Architecture-page {
        width: 100%;
        height: 40%;
    }

    .architech-img {
        width: 100%;
    }

    .user-interface {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-user-page {
        width: 100%;
    }

    .user-img {
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
    }

    .swms-content-liner-color-img {
        height: 20%;
    }

    .swms-btn-lets {
        align-items: center;
        justify-content: center;
        width: 41%;
        height: 44%;
    }

    .swms-lets-talk {
        font-size: 12px;
        padding: 10px 20px;
    }
}

@media only screen and (min-width:768px) and (max-width: 991px) {
    .swms-sub {
        height: 100%;
        width: 100%;
    }

    .swms-consult {
        width: 80%;
        font-weight: 600;
        line-height: 25px;
        font-size: 14px;
    }

    .swms-first-img {
        width: 100%;
        height: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-new-img {
        width: 100%;
        height: 100%;
    }

    .swms-first-phone-view {
        display: none;
    }

    .swms-text-contact-phone-view {
        display: none;
    }

    .swms-para-contact-phone-view {
        display: none;
    }

    .swms-phone-view-first-text {
        display: none;

    }

    .swms-page-contact {
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .swms-text-contact {
        width: 100%;
        height: 5%;
        font-size: 12px;
        line-height: 30px;
        font-weight: 600;
    }

    .swms-para-contact {
        justify-content: center;
        text-align: justify;
        width: 100%;
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
    }

    .swms-right-hand-img {
        height: 65%;
        width: 25%;
    }

    .hand-img {
        width: 100%;
        height: 100%;
    }

    .swms-second-img {
        width: 100%;
        height: 40%;
        align-items: center;
        justify-content: space-around;
    }

    .second-img {
        width: 90%;
    }

    .swms-second-contact {
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        width: 88%;
    }

    .swms-second-para {
        flex-direction: column;
        width: 70%;
        font-size: 15px;
        line-height: 23px;
        text-align: justify;
    }

    .swms-second-page {
        width: 25%;
    }

    .swms-third-img {
        width: 100%;
        align-items: flex-start;
        justify-content: space-around;
    }

    .swms-third-contact {
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        height: 80%;
        width: 95%;
    }

    .swms-third-para {
        justify-content: center;
        flex-direction: column;
        width: 48%;
        height: 35%;
        font-size: 11px;
        line-height: 25px;
        padding-left: 31px;
    }

    .third-img {
        width: 95%;
    }

    .swms-fourth-img {
        width: 100%;
        height: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-fourth-contact {
        justify-content: center;
        flex-direction: row;
        align-items: center;
        width: 90%;
    }

    .fourth-img {
        width: 100%;
        height: 90%;
    }

    .swms-apple-page {
        width: 90%;
    }

    .swms-content-contact {
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        font-size: 15px;
        line-height: 25px;
    }

    .swms-whole-text {
        height: 60%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .swms-title-download {
        height: 10%;
        font-size: 19px;
    }

    .available-img {
        width: 60%;
        height: 30%;
        align-items: center;
        justify-content: center;
    }

    .download-para {
        line-height: 15px;
        width: 88%;
        text-align: center;
        font-size: 15px;
    }

    .system-Architecture {
        width: 100%;
        align-items: center;
    }

    .swms-Architecture-page {
        width: 70%;
    }

    .architech-img {
        width: 100%;
    }

    .user-interface {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-user-page {
        width: 100%;
    }

    .user-img {
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
    }

    .swms-content-liner-color-img {
        height: 16%;
    }

    .swms-btn-lets {
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 55%;
    }

}

@media only screen and (min-width:992px) and (max-width: 1024px) {
    .swms-sub {
        height: 100%;
        width: 100%;
        display: block;
    }

    .swms-first-img {
        width: 100%;
        height: 72%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-first-phone-view {
        display: none;
    }

    .swms-new-img {
        width: 100%;
        height: 100%;
    }

    .swms-text-contact-phone-view {
        display: none;
    }

    .swms-para-contact-phone-view {
        display: none;
    }

    .swms-phone-view-first-text {
        display: none;

    }

    .swms-page-contact {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .swms-text-contact {
        width: 100%;
    }

    .swms-para-contact {
        justify-content: center;
        text-align: justify;
        align-items: center;
        width: 100%;
    }

    .swms-right-hand-img {
        width: 100%;
    }

    .hand-img {
        width: 100%;
    }

    .swms-second-img {
        width: 100%;
        height: 50%;
    }

    .second-img {
        width: 100%;
    }

    .swms-second-contact {
        justify-content: space-around;
        width: 100%;
    }

    .swms-second-para {
        justify-content: center;
        width: 60%;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        text-align: justify;
    }

    .swms-second-page {
        width: 20%;
    }

    .swms-third-img {
        width: 100%;
    }

    .swms-third-contact {
        justify-content: center;
        width: 95%;
        height: 99%;
    }

    .third-img {
        height: 79%;
    }

    .swms-third-para {
        width: 45%;
        font-size: 15px;
        line-height: 25px;
    }

    .swms-fourth-img {
        width: 100%;
        padding-top: 0px;
    }

    .swms-fourth-contact {
        width: 95%;
    }

    .fourth-img {
        width: 100%;
    }

    .swms-apple-page {
        width: 100%;
    }

    .swms-content-contact {
        width: 100%;
    }

    .swms-whole-text {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .swms-title-download {
        height: 10%;
    }

    .available-img {
        width: 35%;
    }

    .download-para {
        width: 62%;
        text-align: center;
        font-size: 11px;
    }

    .system-Architecture {
        width: 100%;
    }

    .swms-Architecture-page {
        width: 70%;
    }

    .architech-img {
        width: 80%;
    }

    .user-interface {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-user-page {
        width: 100%;
    }

    .user-img {
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
    }



    .swms-btn-lets {
        align-items: center;
        justify-content: center;
        width: 40%;
        height: 50%;
        border-radius: 18px;
    }

}

@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .swms-sub {
        height: 100%;
        width: 100%;
        display: block;
    }

    .swms-first-img {
        width: 100%;
        height: 100% !important;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-first-phone-view {
        display: none;
    }

    .swms-new-img {
        width: 100%;
        height: 100%;
    }

    .swms-text-contact-phone-view {
        display: none;
    }

    .swms-para-contact-phone-view {
        display: none;
    }

    .swms-phone-view-first-text {
        display: none;

    }

    .swms-page-contact {
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .swms-text-contact {
        width: 52%;
    }

    .swms-para-contact {
        justify-content: center;
        text-align: justify;
        width: 100%;
        display: flex;
    }

    .swms-right-hand-img {
        justify-content: flex-start;
        align-items: flex-start;
        width: 40%;
        height: 70%;
    }

    .hand-img {
        height: 100%;
        width: 100%;
    }

    .swms-second-img {
        width: 100%;
        padding-top: 70px;
        height: 40%;
    }

    .second-img {
        width: 100%;
        position: relative;
    }

    .swms-second-contact {
        justify-content: space-around;
        width: 100%;
    }

    .swms-second-para {
        justify-content: center;
        width: 60%;
        font-weight: 550;
        font-size: 18px;
        line-height: 25px;
        text-align: justify;
    }

    .swms-second-page {
        width: 20%;
    }

    .swms-third-img {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 70px;
    }

    .swms-third-contact {
        justify-content: center;
        width: 95%;
        height: 99%;
    }

    .third-img {
        height: 79%;
    }

    .swms-third-para {
        width: 45%;
        font-size: 15px;
        line-height: 25px;
    }

    .swms-fourth-img {
        width: 100%;
        height: 100%;
        padding-top: 60px;
    }

    .swms-fourth-contact {
        width: 95%;
        height: 100%;
    }

    .fourth-img {
        width: 90%;
    }

    .swms-apple-page {
        height: 100%;
    }

    .swms-content-contact {
        width: 100%;
    }

    .swms-whole-text {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .swms-title-download {
        height: 10%;
    }

    .available-img {
        width: 40%;
    }

    .download-para {
        width: 55%;
        text-align: center;
        font-size: 17px;
        line-height: 25px;
        font-weight: 550;
    }

    .system-Architecture {
        width: 100%;
    }

    .swms-Architecture-page {
        width: 100%;
    }

    .architech-img {
        width: 100%;
    }

    .user-interface {
        width: 100%;
        align-items: flex-end;
        justify-content: space-around;
    }

    .swms-user-page {
        width: 100%;
    }

    .user-img {
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
    }



    .swms-btn-lets {
        align-items: center;
        justify-content: center;
        width: 40%;
        height: 50%;
        border-radius: 18px;
    }


}