* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.container {
    width: 100%;
}

.service {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    bottom: 10rem;
    position: relative;
}

.service-para {
    width: 75%;
    font-family: 'Montserrat';
    font-style: normal;
    text-align: justify;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;

}

.service-head {
    display: flex;
    justify-content: center;
    align-items: center;
}

.globeImg {
    max-width: 100%;
    width: 800px;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
}

.contentImg {
    max-width: 100%;
    width: 70rem;
    height: auto;
    display: block;
    margin: 0 auto;
}

.content {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.serve-blur {
    background-image: url(/src/assets/images/serveblur.png);
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    background-size: cover;
}

.service-content {
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.service-button {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.consult {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    text-align: justify;
    width: 50%;
}

.talk {

    background-color: #e8fa7e;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    padding: 10px 50px;
    cursor: pointer;

}

@media only screen and (min-width:280px) and (max-width: 400px) {
    .service {
        top: 0rem;
    }

    .service-para {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        padding: 0px 20px;
    }

    .serve-blur {
        height: 6%;
    }

    .consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;

    }

    .talk {
        font-size: 12px;
        padding: 10px 10px;
    }

    .content {
        padding: 0px 20px;
    }

}

@media only screen and (min-width:400px) and (max-width: 767px) {
    .service {
        top: 0rem;
    }

    .service-para {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        padding: 0px 20px;
        text-align: justify;
    }

    .serve-blur {
        height: 6%;
    }

    .consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;

    }

    .talk {
        font-size: 12px;
        padding: 10px 20px;
    }

    .globeImg {
        max-width: 90%;
        width: 800px;
        height: auto;
        display: block;
        margin: 0 auto;
        margin-top: 50px;
    }

    .contentImg {
        max-width: 85%;
        width: 70rem;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}

@media only screen and (min-width:768px) and (max-width: 991px) {
    .service {
        top: 0rem;
    }

    .service-para {
        width: 90%;
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
        margin-top: 5rem;
    }

    .consult {
        width: 80%;
        font-weight: 600;
        line-height: 25px;
        font-size: 14px;
    }

    .globeImg {
        max-width: 70%;
        width: 800px;
        height: auto;
        display: block;
        margin: 0 auto;
        margin-top: 50px;
    }

    .contentImg {
        margin: 0 auto;
        max-width: 85%;
        width: 70rem;
    }

}

@media only screen and (min-width:1024px) and (max-width: 1200px) {
    .service {
        top: 0rem;
        margin: 0px;
    }

    .service-para {
        width: 85%;
        font-size: 18px;
        font-weight: 550;
        line-height: 25px;
    }

    .globeImg {
        max-width: 60%;
        width: 800px;
        height: auto;
        display: block;
        margin: 0 auto;
        margin-top: 50px;
    }

    .contentImg {
        max-width: 70%;
        width: 70rem;
        height: auto;
        display: block;
        margin: 0 auto;

    }

}

@media only screen and (min-width:1040px) {


    .globeImg {
        width: 675px;
    }

    .contentImg {
        width: 62rem;
    }
}


.clients-main-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem
}

.clients-sub-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1rem;
    gap: 1rem;
}

.client-div {
    width: 20%;
    min-width: 210px;
    height: 200px;
    background-color: white;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), -2px -2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-div img {
    width: 180px;
}