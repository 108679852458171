* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.career-main {
    width: 100%;
}
.career-main-topadj{
    height: 60px;
}

.career-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
}

.build-up {
    margin-left: 80px;
    font-size: 17px;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
}

.front-clr {
    color: #24ACD6;
}

.cult {
    padding-top: 100px;
    position: relative;
    font-size: 17px;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
}

.culture {
    position: absolute;
    top: 50%;
    left: 40%;
}

.people {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.organisation {
    margin-left: 30px;
    font-size: 16px;
    font-weight: 550;
    font-family: "Montserrat";
    font-style: normal;
    line-height: 60px;
}


.community {
    font-size: 18px;
    font-weight: 550;
    font-family: "Montserrat";
    font-style: normal;
    line-height: 25px;
}

.four-icon {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 50px;
}

.oriented {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
}

.team {
    width: 100%;
}

.oriented-img {
    width: 150px;
}

@media screen and (max-width: 1440px) {
    .build-up {
        margin-left: 70px;
        width: 812px;
        font-size: 17px;
        font-weight: 600;
        font-family: "Montserrat";
        font-style: normal;


    }

    .cult {
        padding-top: 90px;
        position: relative;
        font-size: 17px;
        font-weight: 600;
        font-family: "Montserrat";
        font-style: normal;
    }

    .culture {
        position: absolute;
        top: 50%;
        left: 40%;
    }

    .people {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .organisation {
        padding-right: 7%;
        padding-left: 2%;
        font-family: "Montserrat";
        font-style: normal;
        font-size: 17px;
        font-weight: 600;
        line-height: 25px;

    }

    .proud {
        font-family: "Montserrat";
        font-style: normal;
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;

    }

    .community {
        font-size: 18px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
    }

    .oriented {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 19px;
        font-weight: 600;
    }

    .oriented-img {
        width: 120px;
    }
}

@media screen and (max-width: 1024px) {
    .build-up {
        margin-left: 60px;
        width: 435px;
        font-size: 17px;
        font-weight: 600;
        font-family: "Montserrat";
        font-style: normal;
    }

    .cult {
        padding-top: 75px;
        position: relative;
        font-size: 17px;
        font-weight: 600;
        font-family: "Montserrat";
        font-style: normal;
    }

    .culture {
        position: absolute;
        top: 50%;
        left: 40%;
    }

    .people {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .organisation {
        padding-right: 5%;
        padding-left: 2%;
        font-size: 17px;
        line-height: 25px;
        font-weight: 600;
        font-family: "Montserrat";
        font-style: normal;

    }

    .proud {
        font-size: 25px;
        line-height: 34px;
        font-weight: 600;
        font-family: "Montserrat";
        font-style: normal;
    }

    .community {
        font-size: 18px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
    }

    .oriented {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
    }

    .oriented-img {
        width: 90px;
    }
}

@media screen and (max-width: 991px) {

    .build-up {
        margin-left: 50px;
        width: 791px;
        font-size: 12px;
        font-weight: 600;
    }

    .cult {
        padding-top: 55px;
        position: relative;
        font-size: 12px;
        font-weight: 600;
        font-family: "Montserrat";
        font-style: normal;
    }

    .culture {
        position: absolute;
        top: 50%;
        left: 30%;
    }

    .people {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .proud {
        font-size: 18px;
        font-weight: 600;
        font-family: "Montserrat";
        line-height: 32px;
    }

    .organisation {
        font-family: "Montserrat";
        font-style: normal;
        font-size: 12px;
        line-height: 32px;
        font-weight: 600;

    }

    .community {
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
    }

    .oriented {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
    }

    .oriented-img {
        width: 80px;
    }
}

@media only screen and (min-width: 425px) and (max-width:767px) {
    .build-up {
        margin-left: 40px;
        font-size: 10px;
        font-weight: 600;
        font-family: "Montserrat";

    }

    .cult {
        padding-top: 35px;
        position: relative;
        font-size: 10px;
        font-weight: 600;

    }

    .culture {
        position: absolute;
        top: 50%;
        left: 37%;

    }

    .people {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .proud {
        font-family: "Montserrat";
        font-size: 14px;
        line-height: 25px;
        font-weight: 600;
    }

    .organisation {
        font-family: "Montserrat";
        font-style: normal;

    }

    .community {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
    }

    .oriented {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
    }

    .oriented-img {
        width: 70px;
    }
}

@media only screen and (min-width: 375px) and (max-width:425px) {
    .build-up {
        margin-left: 40px;
        font-size: 10px;
        font-weight: 600;
        font-family: "Montserrat";

    }

    .cult {
        padding-top: 35px;
        position: relative;
        font-size: 10px;
        font-weight: 600;

    }

    .culture {
        position: absolute;
        top: 50%;
        left: 28%;
    }

    .people {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 25px;

    }

    .proud {
        font-family: "Montserrat";
        font-size: 10px;
        line-height: 20px;
        font-weight: 600;
    }

    .organisation {
        font-family: "Montserrat";
        font-style: normal;

    }

    .community {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
    }

    .oriented {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
    }

    .oriented-img {
        width: 70px;
    }
}

@media only screen and (min-width:301px) and (max-width: 375px) {
    .build-up {
        margin-left: 30px;
        width: 150px;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        font-family: "Montserrat";

    }

    .cult {
        padding-top: 25px;
        position: relative;
        font-size: 10px;
        font-weight: 600;

    }

    .culture {
        position: absolute;
        top: 59%;
        left: 18%;
    }

    .people {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 25px;
    }

    .proud {
        font-family: "Montserrat";
        font-size: 10px;
        line-height: 20px;
        font-weight: 600;
    }

    .organisation {
        font-family: "Montserrat";
        font-style: normal;

    }

    .community {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
    }

    .oriented {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 8px;
        font-weight: 600;
    }

    .oriented-img {
        width: 60px;
    }
}

@media screen and (max-width: 300px) {
    .build-up {
        margin-left: 30px;
        width: 150px;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        font-family: "Montserrat";

    }

    .cult {
        padding-top: 25px;
        position: relative;
        font-size: 10px;
        font-weight: 600;

    }

    .culture {
        position: absolute;
        top: 59%;
        left: 18%;
    }

    .people {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 25px;
    }

    .proud {
        font-family: "Montserrat";
        font-size: 10px;
        line-height: 20px;
        font-weight: 600;
    }

    .organisation {
        font-family: "Montserrat";
        font-style: normal;

    }

    .community {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
    }

    .oriented {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 8px;
        font-weight: 600;
    }

    .oriented-img {
        width: 60px;
    }
}