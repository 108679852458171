* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.dashboard-page {
    min-height: 100vh;
    width: 100% !important;
    margin-top: 1%;
}

.home-container-page {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer-class {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home-first-pages {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 100px;
}

.left-side-home {
    display: flex;
    order: 1;
    justify-content: center;
    align-items: center;
    width: 50%;

}

.left-side-home-head {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;

}

.heading-text-page {
    width: 80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: #5FC5FF;
    margin-bottom: 20px;

}

.home-para {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
    font-family: 'Montserrat';
    text-align: justify;
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 25px;
    color: #010628;
    margin-bottom: 20px;

}

.mobile-content {
    display: none;
}

.home-page-btn {
    height: 100%;
    width: 80%;
    margin-top: 10px;

}

.button-enquiry {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 50px;
    background: #E8FA7E;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.35);
    border-radius: 12px;

}

.Our-Expertise-click {
    background-color: #E8FA7E;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 550;
    border: none;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

.img-groups {
    width: 95% !important;
}

.group-page-img {
    display: flex;
    order: 2;
    align-items: flex-start;
    justify-content: flex-start;
    height: 80%;
    width: 50%;
}

.lanch-vector-content {
    width: 90%;
    object-fit: contain;
}

.lanch-vector-line {
    width: 90%;
    display: flex;
}

.lanch-vector-line-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.home-heading-para-vector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
}

.home-heading-vector {
    width: 60%;
    font-family: 'Montserrat';
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 37px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}

.home-para-vector {
    width: 75%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    color: #000000;
}

.home-expertise-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 100%;

}

.succes-full-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("/src/assets/images/Vector-home-img.png");
    height: 20%;
    width: 90%;
    background-size: cover;

}

.succes-img-content {
    width: 90%;
}

.home-system-page {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
}

.home-system-content {
    display: flex;
    order: 1;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 60%;
}

.home-system-content-head {
    width: 80%;

}

.system-home-page-img {
    display: flex;
    order: 2;
    align-items: center;
    justify-content: center;
    width: 40%;
}

.system-home-img {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
}

.systm-img-left {
    width: 100% !important;
}

.our-expertise {
    width: 50%;
    font-size: 16px;
    margin-bottom: 10px;
}

.expertise-para {
    width: 80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 550;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 20px;

}

.min-max-img {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    width: 80%;
    height: 45%;
}

.min-box-img {
    height: 100%;
    width: 40%;
}

.max-box-img {
    height: 90%;
    width: 50%;
}

.expertise-para-footer {
    padding-top: 20px;
    width: 80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 550;
    font-size: 15px;
    line-height: 25px;
    color: #000000;
}

.home-email-subscribe-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    height: 60%;
}

.home-sub-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 50%;
    height: 90%;
}

.news-subscribe {
    height: 50%;
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #000000;
}

.input-box-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 18%;
    background: linear-gradient(180deg, #010420 0%, #01020E 100%);
    box-shadow: 3.34737e-15px 9px 19px rgba(0, 1, 5, 0.35), inset -2.0233e-15px -33.043px 33.043px #00010B, inset 2.0233e-15px 33.043px 33.043px #010422;
    border-radius: 16px;
}

.email-home-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;
    height: 90%;
}

.input-field {
    width: 95%;
    height: 70%;
    outline: none;
    color: #FFFF97;
    border: none;
    background: #1D2044;
    border-radius: 7px;
    font-size: 20px;
    padding-left: 10px;
}

.button-click {
    width: 32%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #FFFF97 0%, #AAB85C 100%);
    box-shadow: inset -5.60253e-16px -9.14963px 9.14963px #9BA754, inset 5.60253e-16px 9.14963px 9.14963px #FFFFA0;
    border-radius: 0px 16px 16px 0px;
}

.button-click-home {
    font-family: 'Montserrat';
    font-style: normal;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}


@media only screen and (max-width: 991px) {
    .home-first-pages {
        padding-top: 50px;
    }
    .mobile-content {
        display: none;
    }

    .home-para {
        font-size: 12px;
        width: 100%;
    }

    .button-enquiry {
        width: 50%;
        height: 40px;
    }

    .home-heading-para-vector {
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        padding: 20px;
    }

    .home-heading-vector {
        width: 65%;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 35px;

    }

    .home-para-vector {
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        text-align: justify;
    }


    .our-expertise {
        width: 100%;
        font-size: 12px;
    }

    .expertise-para {
        width: 100%;
        font-size: 12px;

    }

    .heading-text-page {
        font-size: 11px;
        width: 100%;
    }

    .min-max-img {
        width: 100%;
        font-size: 12px;
    }

    .home-expertise-page {
        height: 150px;
    }

    .expertise-para-footer {
        width: 100%;
        font-size: 12px;
        line-height: 20px;
    }

    .Our-Expertise-click {
        font-size: 15px;
    }


    .succes-full-img {
        width: 100%;
        font-size: 10px;
    }

    .left-side-home-head {
        width: 80%;
    }

    .home-page-btn {
        width: 100%;
    }
}

@media only screen and (max-width:767px) {
    .group-page-img {
        order: 1;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .home-first-pages {

        display: flex;
        flex-direction: column;
        padding-top: 50px;
    }

    .left-side-home {
        order: 2;
        width: 100%;
    }

    .left-side-home-head {
        width: 90%;
        margin-bottom: 10px;
    }

    .home-heading-para-vector {
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        padding: 20px;
    }

    .home-heading-vector {
        width: 90%;
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 22px;
    }

    .home-para-vector {
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        text-align: justify;
    }

    .heading-text-page {
        font-size: 10px;
        width: 90%;
    }

    .home-page-btn {
        margin-top: 10px;
        width: 90%;
    }

    .Our-Expertise-click {
        font-size: 14px;
    }

    .button-enquiry {
        width: 50%;
    }

    .mobile-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 90%;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 550;
        font-size: 12px;
        line-height: 20px;
        color: #010628;
        margin-bottom: 20px;
        text-align: justify;
    }

    .home-para {
        display: none;
    }

    .succes-full-img {
        background-size: contain;
        background-repeat: no-repeat;
        font-size: 6px;
        background-position: center;

    }


    .home-system-page {
        flex-direction: column;
    }

    .home-system-content {
        order: 2;
        width: 100%;
    }

    .home-system-content-head {
        width: 90%;
    }

    .system-home-page-img {
        order: 1;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .system-home-img {
        width: 80%;
    }

    .our-expertise {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }

    .min-max-img {
        height: 30%;
    }

    .home-email-subscribe-page {
        height: 30%;
    }

    .news-subscribe {
        font-size: 10px;
        line-height: 25px;
    }

    .home-sub-page {
        width: 90%;
    }

    .input-box-input {
        height: 35%;
    }

    .button-click-home {
        font-size: 14px;
    }

    .input-field {
        font-size: 14px;
    }
}