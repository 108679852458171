* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.about-main {
    width: 100%;
    height: 100vh;
    padding-top: 50px;
}

.about-innvo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.whole-system {
    width: 80%;
}

.about-about {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
    color: #000000;
}

.about-about-us {
    width: 80%;
    margin-left: 150px;
    margin-top: 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    text-align: justify;
    color: #000000;
}

.who {
    margin-left: 165px;
    margin-top: 50px;
}

.about-who {
    margin-left: 160px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 17px;
    line-height: 50px;
    font-weight: 600;
    text-align: left;
    text-transform: capitalize;
    color: #000000;
}

.about-who-content {
    width: 35%;
    margin-left: 160px;
    font-family: 'Montserrat';
    text-align: justify;
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    color: #000000;
}
.what-we-do{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.what {
    margin-top: 50px;
}

.about-what {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}

.about-what-content {
    width: 45%;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    text-align: justify;
    color: #000000;
}

.choose {
    margin-left: 165px;
    margin-top: 150px;
}

.about-choose {
    margin-left: 160px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
    line-height: 50px;
    text-transform: capitalize;
    color: #000000;
}

.about-choose-content {
    width: 35%;
    margin-left: 160px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    text-align: justify;
    line-height: 25px;
    color: #000000;
}

.light {
    width: 100%;
    display: flex;
    justify-content: center;
}

.light-map {
    width: 40%;
}

@media only screen and (min-width:280px) and (max-width: 500px) {
    .about-main{
    padding-top: 5px;
    }

    .whole-system {
        width: 100%;
    }

    .about-about {
        font-size: 10px;
        font-weight: 600;
        line-height: 25px;
    }

    .about-about-us {
        line-height: 20px;
        font-weight: 550;
        width: 90%;
        font-size: 12px;
        margin-left: 15px;
    }

    .who {
        display: flex;
        justify-content: center;
        margin-left: 0;
    }

    .about-who {
        font-weight: 600;
        margin-left: 0;
        text-align: center;
        font-size: 10px;
        line-height: 25px;
    }

    .about-who-content {
        width: 90%;
        margin-left: 20px;
        font-size: 12px;
        text-align: justify;
        font-weight: 550;
        line-height: 20px;
    }

    .what-we-do{
        width: 100%;
        align-items: center;
    }

    .what {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 100px;
    }

    .about-what {
        margin-left: 0;
        text-align: center;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .about-what-content {
        width: 90%;
        font-size: 12px;
        text-align: justify;
        font-weight: 550;
        line-height: 20px;
    }

    .choose {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 100px;
    }

    .about-choose {
        margin-left: 0;
        text-align: center;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .about-choose-content {
        width: 90%;
        margin-left: 20px;
        font-size: 12px;
        text-align: justify;
        line-height: 20px;
        font-weight: 550;
    }

    .light-map {
        width: 60%;
        margin-top: 25px;
    }
}

@media only screen and (min-width:500px) and (max-width: 767px) {
    .about-main{
        padding-top: 5px;
        }
    .whole-system {
        width: 100%;
    }

    .about-about {
        line-height: 25px;
        font-size: 10px;
        font-weight: 600;
    }

    .about-about-us {
        width: 90%;
        font-size: 12px;
        line-height: 20px;
        margin-left: 35px;
        font-weight: 550;
    }

    .who {
        display: flex;
        justify-content: center;
        margin-left: 0;
    }

    .about-who {
        margin-left: 0;
        text-align: center;
        line-height: 25px;
        font-size: 10px;
        font-weight: 600;
    }

    .about-who-content {
        width: 90%;
        margin-left: 35px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 550;
        text-align: justify;
    }

    .what-we-do{
        width: 100%;
        align-items: center;
    }

    .what {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 100px;
    }

    .about-what {
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        margin-left: 0;
        text-align: center;
    }

    .about-what-content {
        width: 90%;
        line-height: 20px;
        font-size: 12px;
        font-weight: 550;
        text-align: justify;
    }

    .choose {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 100px;
    }

    .about-choose {
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        margin-left: 0;
        text-align: center;
    }

    .about-choose-content {
        width: 90%;
        margin-left: 35px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 550;
        text-align: justify;
    }

    .light-map {
        width: 50%;
        margin-top: 25px;
    }
}

@media only screen and (min-width:768px) and (max-width: 1024px) {
    .about-main{
        padding-top: 5px;
        }
    .whole-system {
        width: 100%;
    }

    .about-about {
        line-height: 32px;
        font-size: 12px;
        font-weight: 600;
    }

    .about-about-us {
        width: 90%;
        margin-left: 50px;
        line-height: 25px;
        font-size: 14px;
        font-weight: 550;
    }

    .who {
        margin-left: 50px;
        margin-top: 50px;
    }

    .about-who {
        line-height: 32px;
        font-size: 12px;
        font-weight: 600;
        margin-left: 50px;
    }

    .about-who-content {
        line-height: 25px;
        font-size: 14px;
        font-weight: 550;
        width: 50%;
        margin-left: 50px;
    }

    .what {
        margin-top: 50px;
    }

    .about-what {
        line-height: 32px;
        font-size: 12px;
        font-weight: 600;
    }

    .about-what-content {
        line-height: 25px;
        font-size: 14px;
        font-weight: 550;
        width: 55%;
    }

    .choose {
        margin-left: 50px;
        margin-top: 50px;
    }

    .about-choose {
        line-height: 32px;
        font-size: 12px;
        font-weight: 600;
        margin-left: 50px;
    }

    .about-choose-content {
        width: 50%;
        line-height: 25px;
        font-size: 14px;
        font-weight: 550;
        margin-left: 50px;
    }

    .light-map {
        width: 50%;
    }
}

@media only screen and (min-width:1024px) and (max-width: 1200px) {
    .about-main{
        padding-top: 5px;
        }
    .about-about-us {
        width: 90%;
        font-size: 18 px;
        margin-left: 50px;
    }

    .who {
        margin-left: 80px;
    }

    .about-who {
        margin-left: 80px;
    }

    .about-who-content {
        width: 50%;
        margin-left: 80px;
    }

    .about-what-content {
        width: 60%;
    }

    .choose {
        margin-left: 80px;
    }

    .about-choose {
        margin-left: 80px;
    }

    .about-choose-content {
        width: 50%;
        margin-left: 80px;
    }
}

@media only screen and (min-width:1201px) and (max-width: 1350px) {
    .about-main{
        padding-top: 5px;
        }
    .about-about-us {
        width: 90%;
        font-size: 17 px;
        margin-left: 60px;
    }

    .about-what-content {
        width: 40%;
    }
}

@media only screen and (min-width:1351px) and (max-width: 1515px) {
    .about-main{
        padding-top: 5px;
        }
    .about-about-us {
        width: 90%;
        font-size: 18px;
        margin-left: 60px;
    }

    .about-what-content {
        width: 40%;
    }
}