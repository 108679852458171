* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.dtds-main {
    width: 100%;
    height: 100vh;
}

.dtds-pic {
    width: 100%;
}

.image-head{
    position: relative;
    width:100%;
}
.clickable-img {
    position: absolute;
    top: 78%;
    left: 63%;
    width: 4%;
    height: 7%;
    cursor: pointer;
    background-color: transparent;
}

.dtds-patient {
    font-family: 'Montserrat';
    font-style: normal;
    text-transform: capitalize;
}

.dtds-token {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.dtds-token-app {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.dtds-patient-app {
    font-size: 17px;
    font-weight: 600;
    width: 50%;
}

.dtds-app {
    width: 25%;
}

.dtds-book-app {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.dtds-book {
    width: 25%;
}

.doctor-app {
    width: 50%;
    font-family: Montserrat;
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
}

.doctor {
    font-family: 'Montserrat';
    text-align: right;
    text-transform: capitalize;
    color: #000000;
}

.login {

    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    text-align: justify;
    color: #000000;
}

.display-dtds {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.display-token-app {
    width: 50%;
    font-family: Montserrat;
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
}

.display {

    font-family: 'Montserrat';
    text-transform: capitalize;
    color: #000000;
}

.display-token {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    color: #000000;
}

.token-no {
    width: 25%;
}

.dtds-user-app {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.non-mob {
    width: 25%;
}

.dtds-app-application {
    width: 50%;
    font-family: Montserrat;
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
}

.dtds-user {
    font-family: 'Montserrat';
    font-style: normal;
    text-align: right;
    text-transform: capitalize;
    color: #000000;
}

.dtds-application {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    text-align: justify;
    color: #000000;
}

.dtds-mob {

    width: 100%;
}

.dtds-blur {
    background-image: url(/src/assets/images/serveblur.png);
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
    background-size: cover;

}

.dtds-content{
    width:70%;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.dtds-button{
    width:30%;
    display: flex;
    justify-content:center;
    align-items: center;
    
}

.dtds-consult {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
    color: #FFFFFF;
    width: 50%;
}

.dtds-talk {
    
    background-color: #e8fa7e;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    padding: 10px 50px;
    cursor: pointer;
}

.dtds-app-app {
    width: 75%;
}

.dtds-book-sys {
    width: 100%;
}

.app-token-no {
    width: 100%;
}

.non-mob-app {
    width: 100%;
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
    .dtds-app-app {
        width: 100%;
    }

    .dtds-token-app {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .dtds-patient {
        text-align: center;
        width: 100%;
        line-height: 40px;
    }

    .dtds-patient-app {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        text-align: center;
    }

    .dtds-token {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
        width: 95%;
        padding-left: 20px;
    }

    .dtds-book-app {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .doctor-app {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .dtds-book {
        width: 75%;
    }

    .doctor {
        width: 100%;
        text-align: center;
        line-height: 40px;
    }

    .login {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
        width: 95%;
        padding-left: 20px;
    }

    .display-dtds {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .display {
        text-align: center;
        line-height: 40px;
    }

    .display-token {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
        width: 95%;
        padding-left: 20px;
    }

    .display-token-app {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .token-no {
        width: 70%;
    }

    .dtds-user-app {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .non-mob {
        width: 70%;
    }

    .dtds-user {
        text-align: center;
        line-height: 40px;
    }

    .dtds-application {
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
        width: 95%;
        padding-left: 20px;
    }

    .dtds-app-application {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .dtds-blur{
        height: 20%;
    }

    .dtds-consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;
    }

    .dtds-talk {
        font-size: 12px;
        padding: 10px 10px;
    }
}

@media only screen and (min-width:500px) and (max-width: 767px) {
    .dtds-patient-app {
        font-size: 12px;
        line-height: 32px;
        font-weight: 600;
    }

    .dtds-token {
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
    }

    .doctor-app {
        font-size: 12px;
        line-height: 32px;
        font-weight: 600;
    }

    .login {
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
    }

    .display-token-app {
        font-size: 12px;
        line-height: 32px;
        font-weight: 600;
    }

    .display-token {
        font-size: 14px;
        font-weight: 550;
        line-height: 25px
    }

    .dtds-app-application {
        font-size: 12px;
        line-height: 32px;
        font-weight: 600;
    }

    .dtds-application {
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
    }

    .dtds-blur{
        height: 20%;
    }

    .dtds-consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;
    }

    .dtds-talk {
        font-size: 12px;
        padding: 10px 20px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .dtds-consult {
        width: 80%;
        font-weight: 600;
        line-height: 25px;
        font-size: 14px;
    }

    .dtds-blur {
       height: 16%;
    }
}