* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.header-main {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.triton-logo {
    padding-left: 2%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 20%;
}

.top-navbar {
    height: 100%;
    width: 100%;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.nav-bar {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 20px;
}

.nav-menu-item {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
    font-weight: bold;
}

.link {
    text-decoration: none;
    color: var(--lite-dark);
    width: 100%;
    height: 100%;
    justify-content: space-between;
    display: flex;
}

.link:hover {
    color: var(--lite-dark);
}

@media (max-width: 768px) {
    .nav-menu-item {
        display: none;
    }

    .dtds-navbar-toggle {
        display: block;
        margin-left: auto;
        margin-right: 1%;
    }

    .triton-logo {
        height: 85px;
    }
}

@media (min-width: 768px) {
    .nav-menu-item {
        height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 10px;
    }

    .dtds-navbar-toggle {
        display: none;
    }
}

@media screen and (min-width: 1880px) {
    .nav-bar {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 25px;
    }

    .nav-menu-item {
        height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 10px;
        font-weight: bold;
    }
}

.active-nav:after {
    content: "";
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    border-radius: 4px;
    margin-top: 5%;
    background: transparent;
    transition: width 0.6s ease, background-color 0.6s ease;
}

.clicked-nav:after {
    width: 100%;
    background-color: #70B944;
}