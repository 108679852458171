* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.main {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: var(--white);
    overflow-y: scroll;
}

.head-component {
    height: 10%;
    display: flex;
    flex-direction: column;
    width: 100%;

}

.body-component {
    height: 90%;
    display: flex;
    flex-direction: column;
    width: 100%;

}

@media only screen and (max-width: 991px) {
    .head-component {
        height: 10%;

    }

    .body-component {
        height: 90%;

    }
}

@media only screen and (max-width: 767px) {
    .head-component {
        height: 15%;

    }

    .body-component {
        height: 85%;

    }
}