* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.our-product {
    width: 100%;
    height: 100vh;
    display: flex;
}

.mask-image {
    background: url("/src/assets/images/bg-img.png");
    background-size: cover;
    height: 100%;
    width: 100%;
    display: block;
}

.swms-page-product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 150px;
}

.swms-content-product {
    width: 50%;
    height: 100%;
    font-size: 17px;
    font-family: "Montserrat";
    margin-right: 3%;
}

.swms-text-product {
    font-weight: 600;
}

.swms-blue-product {
    color: #24ACD6;
    font-weight: 700;
}

.swms-para-product {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    margin-top: 10px;
    text-align: justify;
}

.swms-view-btn-product {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: justify;
}

.swms-btn-product {
    background: #E8FA7E;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.35);
    border-radius: 18px;
    width: 222px;
    height: 51px;
    border: none;
    font-weight: 600;
    font-size: 18px;
}

.mask-page-product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
}

.mask-content-product {
    width: 50%;
    height: 100%;
    font-size: 17px;
    font-family: "Montserrat";
    margin-left: 80px;
}

.mask-text-product {
    font-weight: 600;
    text-align: justify;
}

.mask-blue-product {
    color: #24ACD6;
    font-weight: 700;
}

.mask-para-product {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    margin-top: 10px;
    text-align: justify;
}

.mask-view-btn-product {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: flex-start;
    justify-content: flex-start;
}

.mask-img-product {
    position: relative;
    top: -108px;
}

.vector-img {
    width: 100%;
}

.vector-product {
    padding-top: 10px;
    position: relative;
}

.vector-text-product {
    font-weight: 700;
    font-size: 40px;
    position: absolute;
    top: 25%;
    left: 30%;
}

.dtds-img-product {
    width: 50%;
}

.dtds-page-product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dtds-content-product {
    width: 50%;
    font-size: 17px;
    font-family: "Montserrat";
    margin-left: 80px;
}

.dtds-text-product {
    font-weight: 600;
    text-align: justify;
}

.dtds-blue-product {
    color: #24ACD6;
    font-weight: 600;
}

.dtds-para-product {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    margin-top: 10px;
    text-align: justify;
}

.dtds-btn-product {
    margin-top: 25px;
    background: #E8FA7E;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.35);
    border-radius: 18px;
    width: 222px;
    height: 51px;
    left: 170px;
    top: 1501px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.input-box-product {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 30%;
    background: linear-gradient(180deg, #010420 0%, #01020E 100%);
    box-shadow: 3.34737e-15px 9px 19px rgba(0, 1, 5, 0.35), inset -2.0233e-15px -33.043px 33.043px #00010B, inset 2.0233e-15px 33.043px 33.043px #010422;
    border-radius: 16px;
    padding-left: 25px;
}

.email-box-product {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
    height: 100%;
}

.input-product {
    outline: none;
    color: #FFFF97;
    width: 95%;
    height: 70%;
    border: none;
    background: #1D2044;
    border-radius: 7px;
    font-size: 20px;
}

.product-email-pages {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
}

.button-product {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 100%;
    background: linear-gradient(180deg, #FFFF97 0%, #AAB85C 100%);
    box-shadow: inset -5.60253e-16px -9.14963px 9.14963px #9BA754, inset 5.60253e-16px 9.14963px 9.14963px #FFFFA0;
    border-radius: 0px 16px 16px 0px;
}

.button-click-product {
    font-family: 'Montserrat';
    font-style: normal;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}

.vital-img-product {
    width: 50%;
}

.vital-page-product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vital-content-product {
    width: 60%;
    height: 100%;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Montserrat";
}

.vital-text-product {
    width: 80%;
    font-weight: 600;
    text-align: left;
}

.vital-para-product {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    margin-top: 10px;
    text-align: justify;
}

.vital-btn-product {
    background: #E8FA7E;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.35);
    border-radius: 18px;
    width: 222px;
    height: 51px;
    margin-top: 20px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.vital-view-btn-product {
    width: 80%;
    display: flex;
    justify-content: flex-start;
}

@media only screen and (max-width: 320px) {
    .mask-image {
        height: 80%;
        width: 100%;
    }

    .mask-page-product {
        width: 87%;
        padding-top: 150px;
        flex-direction: column;
    }

    .mask-content-product {
        margin-left: 28px;
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .mask-text-product {
        text-align: left;
    }

    .mask-para-product {
        width: 100%;
        height: 155px;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
    }

    .mask-img-product {
        position: relative;
        top: -74px;
        width: 286px;

    }

    .vector-text-product {
        font-weight: 600;
        font-size: 24px;
        position: absolute;
        top: 25%;
        left: 30%;
    }

    .dtds-page-product {
        width: 100%;
        align-items: center;
        flex-direction: column-reverse;
    }

    .dtds-content-product {
        width: 88%;
        margin-left: 17px;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .dtds-para-product {
        width: 90%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .dtds-img-product {
        width: 100%;
        height: 300px;
        margin-top: 20px;
    }

    .dtds-img-product-swms {
        width: 100%;
        height: 319px;
    }

    .dtds-btn-product {
        border-radius: 17px;
        width: 148px;
        height: 36px;
        font-weight: 600;
        font-size: 12px;
    }

    .swms-page-product {
        width: 100%;
        padding-top: 65px;
        height: 486px;
        flex-direction: column;
    }

    .swms-content-product {
        width: 235px;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        margin-right: 13px;
    }

    .swms-para-product {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .swms-view-btn-product {
        padding-left: 25px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .vital-page-product {
        width: 100%;
        font-size: 12px;
        text-align: right;
        flex-direction: column-reverse;
        margin-top: 350px;
    }

    .vital-content-product {
        width: 84%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        margin-left: -15px;
    }

    .vital-view-btn-product {
        width: 100%;
    }

    .vital-para-product {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .vital-text-product {
        width: 100%;
    }

    .vital-btn-product {
        border-radius: 17px;
        width: 148px;
        height: 36px;
        left: 170px;
        top: 1501px;
        border: none;
        font-weight: 600;
        font-size: 12px;
    }

    .vital-img-product {
        width: 100%;
    }

    .input-box-product {
        width: 80%;
        height: 30%;
        border-radius: 16px;
        padding-left: 25px;
    }

    .email-box-product {
        width: 100%;
        height: 100%;
    }

    .input-product {
        width: 95%;
        height: 50%;
        border-radius: 7px;
        font-size: 16px;
    }

    .product-email-pages {
        height: 30%;
    }

    .button-product {
        width: 60%;
        height: 100%;
        border-radius: 0px 16px 16px 0px;
    }

    .button-click-product {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
    }
}

@media only screen and (min-width:320px) and (max-width: 375px) {
    .mask-image {
        height: 80%;
        width: 100%;
    }

    .mask-page-product {
        width: 87%;
        padding-top: 150px;
        flex-direction: column;
    }

    .mask-content-product {
        margin-left: 28px;
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .mask-text-product {
        text-align: left;
    }

    .mask-para-product {
        width: 100%;
        height: 155px;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
    }

    .mask-img-product {
        position: relative;
        top: -74px;
        width: 286px;
    }

    .vector-text-product {
        font-weight: 600;
        font-size: 24px;
        position: absolute;
        top: 25%;
        left: 30%;
    }

    .dtds-page-product {
        width: 100%;
        align-items: center;
        flex-direction: column-reverse;
    }

    .dtds-content-product {
        width: 88%;
        margin-left: 17px;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .dtds-para-product {
        width: 90%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;

    }

    .dtds-img-product {
        width: 100%;
        height: 300px;
        margin-top: 20px;
    }

    .dtds-img-product-swms {
        width: 100%;
        height: 319px;
    }

    .dtds-btn-product {
        border-radius: 17px;
        width: 148px;
        height: 36px;
        font-weight: 600;
        font-size: 12px;
    }

    .swms-page-product {
        width: 100%;
        height: 486px;
        flex-direction: column;
        padding-top: 65px;
    }

    .swms-content-product {
        width: 270px;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        margin-right: 13px;
    }

    .swms-para-product {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .swms-view-btn-product {
        padding-left: 0px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .vital-page-product {
        width: 100%;
        font-size: 12px;
        text-align: right;
        flex-direction: column-reverse;
        margin-top: 350px;
    }

    .vital-text-product {
        width: 100%;
    }

    .vital-content-product {
        width: 84%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        margin-left: -15px;
    }

    .vital-view-btn-product {
        width: 100%;
    }

    .vital-para-product {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .vital-btn-product {
        border-radius: 17px;
        width: 148px;
        height: 36px;
        left: 170px;
        top: 1501px;
        border: none;
        font-weight: 600;
        font-size: 12px;
    }

    .vital-img-product {
        width: 100%;
    }

    .input-box-product {
        width: 80%;
        height: 30%;
        border-radius: 16px;
        padding-left: 25px;
    }

    .email-box-product {
        width: 100%;
        height: 100%;
    }

    .input-product {
        width: 95%;
        height: 50%;
        border-radius: 7px;
        font-size: 16px;
    }

    .product-email-pages {
        height: 30%;
    }

    .button-product {
        width: 60%;
        height: 100%;
        border-radius: 0px 16px 16px 0px;
    }

    .button-click-product {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
    }
}

@media only screen and (min-width: 375px) and (max-width:425px) {

    .mask-image {
        height: 80%;
        width: 100%;
    }

    .mask-page-product {
        width: 87%;
        padding-top: 150px;
        flex-direction: column;
    }

    .mask-content-product {
        margin-left: 28px;
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .mask-text-product {
        text-align: left;
    }

    .mask-para-product {
        width: 100%;
        height: 155px;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
    }

    .mask-img-product {
        position: relative;
        top: -74px;
        width: 286px;
    }

    .vector-text-product {
        font-weight: 600;
        font-size: 24px;
        position: absolute;
        top: 25%;
        left: 30%;
    }

    .dtds-page-product {
        width: 100%;
        align-items: center;
        flex-direction: column-reverse;
    }

    .dtds-content-product {
        width: 90%;
        margin-left: 5px;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .dtds-para-product {
        width: 95%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .dtds-img-product {
        width: 100%;
        height: 300px;
        margin-top: 20px;
    }

    .dtds-img-product-swms {
        width: 100%;
        height: 319px;
    }

    .dtds-btn-product {
        border-radius: 17px;
        width: 148px;
        height: 36px;
        font-weight: 600;
        font-size: 12px;
    }

    .swms-page-product {
        width: 100%;
        padding-top: 75px;
        height: 486px;
        flex-direction: column;
    }

    .swms-content-product {
        width: 87%;
        font-size: 12px;
        line-height: 25px;
        font-weight: 600;
        margin-right: 13px;
    }

    .swms-para-product {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .swms-view-btn-product {
        display: flex;
        align-items: flex-start;
        margin-bottom: 11px;
        justify-content: flex-start;
    }

    .vital-page-product {
        width: 100%;
        font-size: 12px;
        text-align: right;
        flex-direction: column-reverse;
        margin-top: 336px;
    }

    .vital-content-product {
        width: 84%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        margin-left: -15px;
    }

    .vital-para-product {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .vital-btn-product {
        border-radius: 17px;
        width: 148px;
        height: 36px;
        left: 170px;
        top: 1501px;
        border: none;
        font-weight: 600;
        font-size: 12px;
    }

    .vital-img-product {
        width: 100%;
        margin-top: 0px;
    }

    .input-box-product {
        width: 80%;
        height: 30%;
        border-radius: 16px;
        padding-left: 25px;
    }

    .email-box-product {
        width: 100%;
        height: 100%;
    }

    .input-product {
        width: 95%;
        height: 50%;
        border-radius: 7px;
        font-size: 16px;
    }

    .product-email-pages {
        height: 30%;
    }

    .button-product {
        width: 60%;
        height: 100%;
        border-radius: 0px 16px 16px 0px;
    }

    .button-click-product {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
    }
}

@media only screen and (min-width: 425px) and (max-width:767px) {
    .mask-image {
        height: 80%;
        width: 100%;
    }

    .mask-page-product {
        width: 87%;
        padding-top: 150px;
        flex-direction: column;
    }

    .mask-content-product {
        margin-left: 28px;
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .mask-text-product {
        text-align: left;
    }

    .mask-para-product {
        width: 100%;
        height: 155px;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
    }

    .mask-img-product {
        position: relative;
        top: -74px;
        width: 286px;
    }

    .vector-text-product {
        font-weight: 600;
        font-size: 24px;
        position: absolute;
        top: 25%;
        left: 30%;
    }

    .dtds-page-product {
        width: 100%;
        align-items: center;
        flex-direction: column-reverse;
    }

    .dtds-content-product {
        width: 90%;
        margin-left: 10px;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
    }

    .dtds-para-product {
        width: 95%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .dtds-img-product {
        width: 100%;
        margin-top: 20px;
        height: 445px;
    }

    .dtds-img-product-swms {
        width: 100%;
        height: 445px;
    }

    .dtds-btn-product {
        border-radius: 17px;
        width: 148px;
        height: 36px;
        font-weight: 600;
        font-size: 12px;
    }

    .swms-page-product {
        width: 100%;
        padding-top: 66px;
        height: 486px;
        flex-direction: column;
    }

    .swms-content-product {
        width: 86%;
        font-size: 12px;
        line-height: 25px;
        font-weight: 600;
        margin-right: 13px;
    }

    .swms-para-product {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .swms-view-btn-product {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

    }

    .vital-page-product {
        width: 100%;
        font-size: 12px;
        text-align: right;
        flex-direction: column-reverse;
        margin-top: 388px;
    }

    .vital-content-product {
        width: 84%;
        font-size: 10px;
        line-height: 25px;
        font-weight: 600;
        margin-left: -15px;

    }

    .vital-para-product {
        width: 100%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .vital-btn-product {
        margin-top: 6%;
        border-radius: 17px;
        width: 148px;
        height: 36px;
        left: 170px;
        top: 1501px;
        border: none;
        font-weight: 600;
        font-size: 12px;
    }

    .vital-img-product {
        width: 100%;
        margin-top: 20px;
    }

    .vital-text-product {
        width: 100%;
        font-weight: 600;
        text-align: left;
    }

    .vital-view-btn-product {
        margin-right: 75px;
    }

    .input-box-product {
        width: 80%;
        height: 30%;
        border-radius: 16px;
        padding-left: 25px;
    }

    .email-box-product {
        width: 100%;
        height: 100%;
    }

    .input-product {
        width: 95%;
        height: 50%;
        border-radius: 7px;
        font-size: 16px;
    }

    .product-email-pages {
        height: 30%;
    }

    .button-product {
        width: 60%;
        height: 100%;
        border-radius: 0px 16px 16px 0px;
    }

    .button-click-product {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
    }

}

@media only screen and (min-width:768px) and (max-width: 991px) {

    .mask-image {
        height: 60%;
        width: 100%;
    }

    .mask-content-product {
        width: 50%;
        margin-left: 80px;
        font-weight: 600;
        line-height: 32px;
        font-size: 12px;
        text-align: justify;
    }

    .mask-text-product {
        width: 80%;
    }

    .mask-img-product {
        position: relative;
        top: -112px;
        height: 435px;
        width: 385px;
    }

    .mask-para-product {
        width: 100%;
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
    }

    .vector-text-product {
        font-weight: 600;
        font-size: 30px;
        position: absolute;
        top: 25%;
        left: 30%;
    }

    .dtds-btn-product {
        border-radius: 18px;
        width: 140px;
        height: 38px;
        left: 170px;
        top: 1501px;
        border: none;
        font-weight: 600;
        font-size: 14px;
    }

    .dtds-page-product {
        width: 100%;
        padding-top: 1%;
    }

    .dtds-img-product {
        width: 30%;
    }

    .dtds-img-product-swms {
        width: 30%;
    }

    .dtds-content-product {
        width: 100%;
        height: 100%;
        font-weight: 600;
        line-height: 32px;
        font-size: 12px;
        margin-left: 80px;
    }

    .dtds-para-product {
        width: 100%;
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .swms-page-product {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10%;
    }

    .swms-content-product {
        width: 69%;
        font-weight: 600;
        line-height: 32px;
        font-size: 12px;
        text-align: justify;
    }

    .swms-para-product {
        width: 100%;
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .input-box-product {
        width: 60%;
        height: 30%;
        border-radius: 16px;
        padding-left: 25px;
    }

    .dtds-btn-product {
        border-radius: 18px;
        width: 140px;
        height: 38px;
        left: 170px;
        top: 1501px;
        border: none;
        font-weight: 600;
        font-size: 14px;
    }

    .vital-page-product {
        width: 100%;
        padding-top: 10%;

    }

    .vital-img-product {
        width: 30%;
    }

    .vital-content-product {
        width: 100%;
        height: 100%;
        font-weight: 600;
        line-height: 32px;
        font-size: 12px;
        text-align: justify;
        margin-left: 80px;
    }

    .vital-text-product {
        width: 100%;
        font-weight: 600;
        text-align: left;
    }

    .vital-para-product {
        width: 100%;
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .vital-btn-product {
        width: 140px;
        height: 37px;
        font-size: 14px;
    }

    .vital-view-btn-product {
        width: 100%;
    }

    .email-box-product {
        width: 100%;
        height: 100%;
    }

    .input-product {
        width: 95%;
        height: 50%;
        border-radius: 7px;
        font-size: 16px;
    }

    .product-email-pages {
        height: 30%;
    }

    .button-product {
        width: 60%;
        height: 100%;
        border-radius: 0px 16px 16px 0px;
    }

    .button-click-product {
        border: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
    }

    .swms-view-btn-product {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        text-align: justify;
    }
}

@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .mask-image {
        height: 80%;
        width: 100%;
        display: block;
    }

    .swms-view-btn-product {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .mask-text-product {
        text-align: left;
    }

    .mask-para-product {
        width: 100%;
        font-size: 18px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
        margin-top: 20px;
    }

    .mask-content-product {
        width: 35%;
        height: 100%;
        font-weight: 600;
        text-align: left;
        font-size: 17px;
        margin-left: 80px;
    }

    .mask-page-product {
        width: 100%;
        padding-top: 50px;
    }

    .vector-text-product {
        font-weight: 600;
        font-size: 40px;
        position: absolute;
        top: 25%;
        left: 30%;
    }

    .dtds-btn-product {
        margin-top: 3%;
        border-radius: 18px;
        width: 190px;
        height: 45px;
        left: 170px;
        top: 1501px;
        font-weight: 600;
        font-size: 16px;
    }

    .dtds-page-product {
        width: 100%;
        padding-top: 1%;
    }

    .dtds-img-product {
        width: 40%;
    }

    .dtds-img-product-swms {
        width: 40%;
    }

    .dtds-content-product {
        width: 43%;
        height: 100%;
        margin-left: 80px;
        font-size: 17px;
        font-weight: 600;
    }

    .dtds-para-product {
        width: 100%;
        font-size: 18px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .swms-content-product {
        width: 50%;
        height: 100%;
        font-size: 17px;
        font-weight: 600;
        margin-right: 80px;
    }

    .swms-para-product {
        width: 100%;
        font-size: 18px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .input-box-product {
        width: 70%;
        height: 40%;
        border-radius: 16px;
        padding-left: 25px;
    }

    .email-box-product {
        width: 65%;
        height: 100%;
    }

    .input-product {
        width: 95%;
        height: 70%;
        border-radius: 7px;
        font-size: 20px;
    }

    .product-email-pages {
        height: 30%;
    }

    .button-product {
        width: 35%;
        height: 100%;
        border-radius: 0px 16px 16px 0px;
    }

    .button-click-product {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
    }

    .vital-img-product {
        width: 40%;
    }

    .vital-page-product {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 145px;
    }

    .vital-content-product {
        width: 85%;
        font-size: 17px;
        font-family: "Montserrat";
        align-items: center;
        justify-content: center;
    }

    .vital-text-product {
        width: 80%;
        font-weight: 600;
        text-align: left;
    }

    .vital-para-product {
        width: 80%;
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: 550;
        line-height: 25px;
        text-align: justify;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .vital-btn-product {
        margin-top: 3%;
        border-radius: 18px;
        width: 190px;
        height: 45px;
        font-weight: 600;
        font-size: 16px;
    }

    .vital-view-btn-product {
        display: flex;
        justify-content: flex-start;
    }
}