* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.terms-condition-main-page {
    width: 100%;
    height: 100vh;
    display: flex;

}

.terms-condition-topadj {
    height: 60px;
}

.sub-terms-condition-page {
    height: 100%;
    width: 100%;
    display: block;
}

.contant-terms-condition-page {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 90%;
}

.terms-condition-heading-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    width: 80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.terms-condition-move-text-center {
    width: 90%;
}

.terms-condition-move-text {
    width: 80%;
}

.all-terms-condition-para-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    background-image: url("/src/assets/images/triton-log-background-img.png");
    background-size: cover;
    background-position: center;
}

.terms-condition-para-text {
    padding: 25px;
    text-align: justify;
    width: 90%;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    font-weight: 550;
}


@media only screen and (max-width: 767px) {
    .contant-terms-condition-page {
        width: 100%;
    }

    .terms-condition-heading-text {
        width: 100%;
        text-align: justify;
        font-weight: 600;
        font-size: 12px;
        line-height: 25px;
    }

    .all-terms-condition-para-main {
        width: 100%;
    }

    .terms-condition-para-text {
        text-align: justify;
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 550;
    }

    .terms-condition-move-text {
        width: 100%;
    }
}

@media only screen and (min-width:768px) and (max-device-width: 1024px) {
    .contant-terms-condition-page {
        width: 100%;
    }

    .terms-condition-heading-text {
        width: 100%;
        text-align: justify;
        font-weight: 600;
        font-size: 14px;
        line-height: 25px;
    }

    .all-terms-condition-para-main {
        width: 100%;
    }

    .terms-condition-para-text {
        text-align: justify;
        width: 100%;
        font-size: 14px;
        line-height: 25px;
        font-weight: 550;
    }

    .terms-condition-move-text {
        width: 100%;
    }
}