.contact-us-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-head {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-container {
    background: url(../assets/images/contact-blue.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 50%;
}

.contact-sub-container {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    margin-top: 100px;
}

.contact-mail {
    text-decoration: none;
    margin: 10px 0px;
    color: black;
}

.contact-mail-in {
    text-decoration: none;
    margin: 10px 0px;
    color: black;
}

.contact-mail-in:hover {
    text-decoration: none;
    margin: 10px 0px;
    color: blue;
}

.contact-title-head {
    font-size: 30px;
}

.contact-card {
    width: 90%;
    display: flex;
    align-items: self-start;
    background-color: #000318;
    box-shadow: 0px -12px 22px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    margin: 20px auto;
    border-radius: 30px;
    color: white;
}

.form {
    color: white;
    margin-top: 15px;
    padding: 20px 20px;
    flex-grow: 1;
}

.form-control {
    padding: 1.2rem 1rem;
    border-radius: 10px;
    background: #41414170;
    border: none;
    outline: none;
    color: white;
    font-family: Montserrat;
}

.form-control:focus {
    border: 2px solid blue;
}

.resize-none {
    resize: none;
}

.form-label {
    margin: 5px 0px;
}

.contact-name-field,
.contact-email-field,
.contact-message-field {
    display: flex;
    flex-direction: column;
}

.map {
    padding: 40px 20px;
    width: 50%;
}

.contact {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

.contact-mail,
.contact-phone,
.contact-address {
    width: 35%;
    text-align: center;
}

.error-text {
    color: red;
    margin-left: 20px;
}

.map-iframe {
    width: 100%;
    height: 100%;
}

.contact-btn {
    margin-top: 20px;
    border-radius: 10px;
    background-color: #E8FA7E;
    width: 120px;
    color: black;
    float: right;
    padding: 10px 20px;
    font-weight: 600;
}

.form-message {
    height: 100px;
}

@media screen and (max-width:767px) {
    .contact-container {
        height: 55%;
    }

    .contact-card {
        width: 100%;
        flex-direction: column;
        align-items: initial;
    }

    .contact-title-head {
        font-size: 25px;
    }

    .form-label {
        font-size: 12px;
    }

    .contact {
        margin-top: 40px;
        flex-direction: column;
    }

    .contact-mail,
    .contact-phone,
    .contact-address {
        width: 80%;
        margin-bottom: 20px;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    .contact_img {
        width: 30%;
    }

    .conatct-head {
        font-size: 24px;
    }

    .contact-para {
        font-size: 20px;

    }

    .error-text {
        font-size: 13px;
    }

    .map-iframe {
        width: 100%;
        height: 100%;
    }

    .contact-btn {
        margin-top: 15px;
        border-radius: 10px;
        width: 100%;
        padding: 6px 20px;
        font-weight: 600;
    }

    .form-control {
        padding: 0.5rem 1rem;
    }

    .map {
        padding: 10px 20px;
        width: 100%;
    }

    .form-message {
        height: 70px;
    }

    .conatct-head,
    .contact-para {
        font-size: 15px;
    }
}

@media screen and (min-width:600px) {
    .contact-card {
        width: 100%;
        flex-direction: column;
        align-items: initial;
    }

    .contact-title-head {
        font-size: 25px;
    }

    .form-label {
        font-size: 12px;
    }

    .contact {
        margin-top: 40px;
        flex-direction: column;
    }

    .contact-mail,
    .contact-phone,
    .contact-address {
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }

    .contact_img {
        width: 30%;
    }

    .error-text {
        font-size: 13px;
    }

    .conatct-head {
        font-size: 24px;
    }

    .contact-para {
        font-size: 20px;

    }

    .map-iframe {
        width: 100%;
        height: 100%;
    }

    .form-control {
        padding: 0.8rem 1rem;
    }

    .map {
        padding: 10px 20px;
    }

    .form-message {
        height: 100px;
    }

    .conatct-head,
    .contact-para {
        font-size: 15px;
    }

}

@media screen and (min-width:768px) {
    .contact-card {
        flex-direction: row
    }

    .contact {
        margin-top: 50px;
        flex-direction: row;
    }

    .map-iframe {
        width: 100%;
        height: 100%;
    }

    .form-message {
        height: 100px;
    }
}

@media screen and (min-width:992px) {
    .contact-card {
        flex-direction: row
    }

    .contact {
        flex-direction: row;
        margin-top: 50px;
    }

    .map-iframe {
        width: 100%;
        height: 100%;
    }

    .form-message {
        height: 100px;
    }
}

@media screen and (min-width:1024px) {
    .contact-card {
        flex-direction: row
    }

    .contact {
        margin-top: 50px;
        flex-direction: row;
    }

    .map-iframe {
        width: 100%;
        height: 100%;
    }

    .form-message {
        height: 100px;
    }
}

.address-new-div {
    display: flex;
    justify-content: space-between;
    gap: 1rem
}