.footer {
    width: 100%;
}

.footer-top {
    width: 100%;
    color: azure;
    background: #000318;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: self-start;
    padding: 30px 0px;

}

.fooder-first-line {
    display: flex;
    flex-direction: row;
    align-items: self-start;
    justify-content: space-evenly;
    width: 45%;

}

.fooder-second-line {
    display: flex;
    flex-direction: row;

    align-items: self-start;
    justify-content: space-evenly;
    width: 55%;
}

.footer-logo img {
    width: 100px;

}

.footer-link .footer-sub-link {
    list-style-type: none;
    margin: 15px 0px;
    display: flex;
    flex-direction: row;
}

.footer-sub-content {
    list-style-type: none;
    margin: 15px 0px;
}

.footer-menu {
    width: 20%;
    margin: 0 10px;


}

.footer-services {
    width: 30%;
    margin: 0 20px;


}

.footer-product {
    width: 30%;
    margin: 0 30px;
}

.footer-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-transform: capitalize;
    color: #E8FA7E;
}

.footer-follow-us-on {
    width: 30%;
    margin: 0 10px;

}

.footer-contact {
    width: 40%;
    margin: 0 30px;
}

.footer-end {
    width: 100%;
    color: antiquewhite;
    text-align: center;
    background: #101010;
}

.footer-btn {
    margin-top: 20px;
    background-color: #E8FA7E;
    padding: 10px 50px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.footer-icons {
    display: flex;
    align-items: center;
}

.footer-router-link {
    color: white;
    text-decoration: none;
}

.footer-links {
    color: white;
}

@media screen and (max-width:768px) {
    .footer-top {
        flex-direction: column;
    }

    .fooder-first-line {
        flex-direction: column;
    }

    .fooder-second-line {
        flex-direction: column;
    }

    .footer-logo {
        margin-left: 30px;
        width: 100px;
    }

    .footer-menu {
        width: 100%;
        text-align: left;
        margin: 20px 0px 20px 0px;
        padding-left: 50px;
    }

    .footer-services {
        width: 100%;
        text-align: left;
        margin: 20px 0px 20px 0px;
        padding-left: 50px;

    }

    .footer-product {
        width: 100%;
        text-align: left;
        margin: 20px 0px 20px 0px;
        padding-left: 50px;

    }

    .footer-follow-us-on {
        width: 100%;
        text-align: left;
        margin: 20px 0px 20px 0px;
        padding-left: 50px;

    }

    .footer-contact {
        width: 90%;
        text-align: left;
        margin: 20px 0px 20px 0px;
        padding-left: 50px;

    }
}

@media screen and (max-width:991px) {
    .footer-top {
        flex-direction: column;
        padding-bottom: 10px;
    }

    .fooder-first-line {
        width: 100%;
    }

    .fooder-second-line {
        padding-top: 10px;
        width: 100%;

    }
}