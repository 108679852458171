* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.vital-main-page {
    height: 100vh !important;
    width: 100% !important;
    display: flex;
}

.vital-container-page {
    height: 100%;
    width: 100%;
    display: block;
}

.vital-first-pages {
    width: 100%;
    height: 95%;
    background: url("/src/assets/images/vital-background-first.png");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.vital-page-first-contant {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 45%;
}

.vital-first-heading-contant {
    padding: 5px;
    width: 80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
}

.vital-first-para-contant {
    padding: 5px;
    width: 80%;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    text-align: justify;
}

.vital-first-page-phone {
    width: 50%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.vital-first-page-phone-img {
    width: 60%;

}

.vital-first-page-phone-view {
    display: none;
}

.vital-Color-Img {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 30%;
    width: 100%;
}

.vital-Color-img-background {
    width: 100%;
}

.vital-iot-sensors-img-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 90%;
    width: 100%;
}

.vital-sensors-img-size {
    display: flex;
    justify-content: center;
    width: 20%;
}

.vital-sensors-img {
    width: 25%;
}

.vital-sensors-contents {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    width: 50%;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    text-align: justify;
    color: #000000;
}

.vital-machine-img-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.vital-machine-firat-img-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 80%;
    padding: 5px;
}

.vital-machine-second-img-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 80%;
    padding: 5px;
}

.vital-machine-third-img-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 80%;
    padding: 5px;
}

.vital-machine-img-weight-size {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
}

.vital-machine-img-height-size {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}

.vital-machine-img-temperture-size {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}

.vital-machine-first-img {
    width: 20%;
}

.vital-machine-second-img {
    width: 20%;
}

.vital-machine-third-img {
    width: 30%;
}

.vital-machine-first-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    text-align: justify;
    color: #000000;
}

.vital-machine-second-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    color: #000000;
    text-align: justify;

}

.vital-machine-third-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 550;
    line-height: 25px;
    color: #000000;
    text-align: justify;

}

.vital-page-container {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.vital-dector-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.vital-dector-checkup-img {
    width: 60%;
}

.vital-link-and-phone-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.vital-phone-content-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    height: 50%;
}

.vital-iphone-img {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
}

.vital-click-Stores {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70%;
    width: 100%;
}

.vital-url-link-img {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 50%;
}

.vital-trtion-link-img-app {
    width: 70%;
}

.vital-para-phone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
}

.vital-trtion-link-img {
    width: 70%;
    margin-right: 20px;
}

.vital-dector-checkup-iphone-img {
    width: 60%;
}

.Vital-content-liner-color-img {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30%;
    background-size: cover;
    background-image: url("/src/assets/images/vital-page-liner-color-img.png");
}

.Vital-content-liner-color {
    width:70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.vital-page-btn-liner {
    width:30%;
    display: flex;
    justify-content:center;
    align-items: center;
}

.vital-btn-lets {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 50%;
    background: #E8FA7E;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.35);
    border-radius: 18px;
}

.vital-lets-talk {
    background-color: #e8fa7e;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    padding: 10px 50px;
    cursor: pointer;
}

.vital-consult{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    text-align: justify;
    width: 50%;
}

@media only screen and (max-width: 767px) {
    .vital-container-page {
        width: 100%;
        height: 90% !important;
    }

    .vital-consult {
        line-height: 20px;
        font-size: 12px;
        width: 80%;

    }

    .vital-first-pages {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

    }

    .vital-first-page-phone {
        display: none;
    }

    .vital-first-page-phone-view {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .vital-first-page-phone-img-view {
        width: 50%;
    }

    .vital-page-first-contant {
        width: 90%;
        justify-content: space-around;
        align-items: center;
        height: 90%;
    }

    .vital-first-heading-contant {
        width: 100%;
        font-size: 10px;
        line-height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
    }

    .vital-first-para-contant {
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 550;
    }

    .vital-Color-Img {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 30%;
        width: 100%;
    }

    .vital-Color-img-background {
        width: 100%;
    }

    .vital-iot-sensors-img-content {
        height: 70%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .vital-sensors-img-size {
        width: 30%;
    }

    .vital-sensors-img {
        width: 20%;
    }

    .vital-sensors-contents {
        width: 70%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 550;
        text-align: justify;
    }

    .vital-machine-img-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .vital-machine-firat-img-content {
        width: 100%;
        flex-direction: column;
    }

    .vital-machine-second-img-content {
        width: 100%;
        flex-direction: column;
    }

    .vital-machine-third-img-content {
        width: 100%;
        flex-direction: column;
    }

    .vital-machine-img-weight-size {
        width: 10%;
    }

    .vital-machine-img-height-size {
        width: 10%;
    }

    .vital-machine-img-temperture-size {
        width: 10%;
    }

    .vital-machine-first-img {
        width: 100%;
    }

    .vital-machine-second-img {
        width: 70%;
    }

    .vital-machine-third-img {
        width: 80%;
    }

    .vital-machine-first-content {
        width: 50%;
        font-size: 12px;
        font-weight: 550;
        line-height: 20px;
        text-align: justify;
    }

    .vital-machine-second-content {
        width: 50%;
        font-weight: 550;
        font-size: 12px;
        line-height: 20px;
        text-align: justify;
    }

    .vital-machine-third-content {
        width: 50%;
        font-weight: 550;
        font-size: 12px;
        line-height: 20px;
        text-align: justify;
    }

    .vital-page-container {
        width: 100%;
        display: flex;
        align-items: center;
        height: 50%;
    }

    .vital-dector-img {
        width: 100%;
    }

    .vital-dector-checkup-img {
        width: 80%;
    }

    .vital-click-Stores {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 80%;
        height: 100%;
    }

    .vital-trtion-link-img-app {
        width: 70%;
    }

    .vital-link-and-phone-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        height: 55%;
    }

    .vital-phone-content-link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 60%;
        height: 100%;
    }

    .vital-iphone-img {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 40%;
        height: 100%;
    }

    .vital-url-link-img {
        width: 90%;
        margin: 10px;
        display: flex;
        align-items: flex-start;
        height: 25%;
    }

    .vital-para-phone {
        font-size: 12px;
        line-height: 20px;
        width: 95%;
    }

    .vital-trtion-link-img {
        width: 80%;
        margin-right: 30px;
        margin-bottom: 20px;
        padding: 5px;
    }

    .vital-dector-checkup-iphone-img {
        width: 100%;
    }

    .Vital-content-liner-color-img {
        height: 20%;
    }

    .vital-btn-lets {
        width: 60%;
    }

    .vital-lets-talk {
        font-size: 12px;
        padding: 10px 20px;
    }
}

@media only screen and (min-width:768px) and (max-width: 991px) {
    .vital-container-page {
        width: 100%;
        height: 100%;
    }

    .vital-consult {
        width: 80%;
        font-weight: 600;
        line-height: 25px;
        font-size: 14px;
    }

    .vital-page-first-contant {
        align-items: flex-start;
        width: 45%;
    }

    .vital-first-heading-contant {
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 12px;
        line-height: 32px;
        font-weight: 600;
    }

    .vital-first-para-contant {
        width: 100%;
        font-size: 14px;
        font-weight: 550;
        line-height: 25px;
    }

    .vital-first-pages {
        width: 100%;
    }

    .vital-Color-img-background {
        width: 100%;
    }

    .vital-iot-sensors-img-content {
        width: 100%;
    }

    .vital-sensors-img-size {
        width: 30%;
    }

    .vital-sensors-img {
        width: 25%;
    }

    .vital-sensors-contents {
        width: 50%;
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
        font-weight: 550;
    }

    .vital-machine-img-content {
        width: 100%;
    }

    .vital-machine-firat-img-content {
        width: 80%;
    }

    .vital-machine-second-img-content {
        width: 65%;
    }

    .vital-machine-third-img-content {
        width: 80%;
    }

    .vital-machine-img-weight-size {
        width: 30%;
    }

    .vital-machine-img-height-size {
        width: 30%;
    }

    .vital-machine-img-temperture-size {
        width: 30%;
    }

    .vital-machine-first-img {
        width: 30%;
    }

    .vital-machine-second-img {
        width: 20%;
    }

    .vital-machine-third-img {
        width: 30%;
    }

    .vital-machine-first-content {
        width: 70%;
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
        font-weight: 550;
    }

    .vital-machine-second-content {
        width: 65%;
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
        font-weight: 550;
    }

    .vital-machine-third-content {
        width: 70%;
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
        font-weight: 550;
    }

    .vital-page-container {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .vital-dector-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .vital-dector-checkup-img {
        width: 60%;
    }

    .vital-click-Stores {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    .vital-trtion-link-img-app {
        width: 70%;
    }

    .vital-link-and-phone-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }

    .vital-phone-content-link {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        width: 50%;
    }

    .vital-iphone-img {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 60%;
        height: 95%;
    }

    .vital-url-link-img {
        width: 80%;
        margin-top: 30px;
    }

    .vital-para-phone {
        width: 60%;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }

    .vital-trtion-link-img {
        width: 68%;
    }

    .vital-dector-checkup-iphone-img {
        width: 75%;
    }

    .Vital-content-liner-color-img {
        height: 16%;
    }

    .vital-btn-lets {
        width: 80%;
    }

    .vital-lets-talk {
        font-size: 20px;
        line-height: 10px;
    }
}