* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to left, var(--cement) 50%, var(--royal-blue) 50%) right;
  background-repeat: no-repeat;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.button {
  cursor: pointer;
}